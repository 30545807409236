import { FC, ReactNode, useId } from 'react';
import { ChevronDown } from '../svgs';
import styles from './Accordion.module.scss';

type AccordionProps = {
  items: Array<{
    title: ReactNode;
    body: ReactNode;
  }>
};

export const Accordion: FC<AccordionProps> = ({ items }) => {
  const instanceId = useId();
  const id = index => `accordion-${instanceId}-${index}`;

  return items.map(({ title, body }, index) =>
    <div key={id(index)} className={styles.accordionItem}>
      <input id={id(index)} type="checkbox" className={styles.checkbox} radioGroup='' tabIndex={0} />
      <label htmlFor={id(index)} className={styles.label}>
        <ChevronDown className={styles.icon} aria-hidden />
        {title}
      </label>

      <div className={styles.content}>
        <div>
          {body}
        </div>
      </div>
    </div>
  );
};
